

























































import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'rct-post-footer': () => import('@/modules/recruitment/posts/components/rct-post-footer.vue'),
  },
})
export default class extends Vue {
  @Prop({}) postStore!: PostStore
}
